import { createApiAction as _createApiAction } from '../../utils/index'

const ACTION_KEY = 'auth/'

const createApiAction = _createApiAction(ACTION_KEY)

export const signin = createApiAction(`${ACTION_KEY}SIGNIN`)
export const signup = createApiAction(`${ACTION_KEY}SIGNUP`)
export const verifyOtp = createApiAction(`${ACTION_KEY}VERIFY_OTP`)
export const resendOtp = createApiAction(`${ACTION_KEY}RESENT_OTP`)

export const logout = createApiAction(`${ACTION_KEY}LOGOUT`)