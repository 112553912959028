import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import {
  SessionStorageRole,
  SessionStorageToken,
  SessionStorageUser,
} from "../consts/Fuction";
import ScrollToTop from "../utils/ScrollToTop";
import { useLocation } from "react-router";

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("../containers/Auth/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_403,
    component: lazy(() => import("../containers/Auth/403")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("../containers/Auth/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() =>
      import("../containers/Auth/Registration/Registration")
    ),
  },
];

const privateRoutes = [
  {
    path: "/admin",
    exact: true,
    component: lazy(() => import("../containers/Admin/Admin")),
    role: "admin",
  },
];

function PrivateRoute({ component: Component, ...rest }) {
  const token = SessionStorageToken();
  const userRole = SessionStorageRole();
  const isLoggedIn = token ? true : false;
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("finance")) {
      sessionStorage.setItem("isFinance:agrigator", "true");
    } else {
      sessionStorage.setItem("isFinance:agrigator", "false");
    }
  }, [location]);

  useEffect(() => {
    let USER_DETAILS = SessionStorageUser();
    if (USER_DETAILS) {
      /* window.clientSocket.emit('user logged in', USER_DETAILS); */
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && userRole === rest.role ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
const Routes = ({ history }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<React.Fragment />}>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {privateRoutes.map((route, index) => (
              <PrivateRoute
                key={index}
                path={route.path}
                component={route.component}
                role={route.role}
              />
            ))}
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
