import { createApiAction as _createApiAction, createKeyAction } from '../../utils/index'

const ACTION_KEY = 'notification/'

const createAction = createKeyAction(ACTION_KEY)
const createApiAction = _createApiAction(ACTION_KEY)


export const addNotification = createAction(`${ACTION_KEY}addNotification`)
export const clearNotification = createAction(`${ACTION_KEY}clearAll`)

export const getNotifications = createApiAction(`${ACTION_KEY}getNotifications`)

export const updateNotifications = createApiAction(`${ACTION_KEY}updateNotification`)

