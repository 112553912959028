import { createReducer } from 'redux-act'

import {
    addNotification,
    getNotifications,
    updateNotifications,
    clearNotification
} from '../actions/notification.action'

const initialState = {
    unreadNotifications: [],
    loading: false
}

export default createReducer({

    [clearNotification]: (state, payload) => ({
        ...state,
        unreadNotifications: []
    }),

    [addNotification]: (state, payload) => ({
        ...state,
        unreadNotifications: payload.payload
    }),

    [getNotifications.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getNotifications.success]: (state, payload) => ({
        ...state,
        loading: false,
        unreadNotifications: payload.notifications.sort((a, b) => (a.notificationId < b.notificationId ? 1 : -1))
    }),
    [getNotifications.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [updateNotifications.request]: (state) => ({
        ...state,
        loading: true
    }),
    [updateNotifications.success]: (state, payload) => ({
        ...state,
        loading: false,
        unreadNotifications: payload.notifications
    }),
    [updateNotifications.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),


}, initialState)