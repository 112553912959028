import { createApiAction as _createApiAction } from '../../utils/index'

const ACTION_KEY = 'admin/'

const createApiAction = _createApiAction(ACTION_KEY)

export const dispatch = createApiAction(`${ACTION_KEY}dispatch`)
export const dispatchWithPagination = createApiAction(`${ACTION_KEY}dispatchWithPagination`)
export const viewDispatch = createApiAction(`${ACTION_KEY}viewDispatch`)
export const updateStatus = createApiAction(`${ACTION_KEY}updateStatus`)

export const updateVehicleDetails = createApiAction(`${ACTION_KEY}updateVehicleDetails`)

export const updateInvoice = createApiAction(`${ACTION_KEY}updateInvoice`)

export const createAdvancePayment = createApiAction(`${ACTION_KEY}advancePayment`)
export const createBalancePayment = createApiAction(`${ACTION_KEY}balancePayment`)

export const updateIntransitDetails = createApiAction(`${ACTION_KEY}updateIntransitDetails`)

export const advancePaymentByCheque = createApiAction(`${ACTION_KEY}advancePaymentCheque`)
export const balancePaymentByCheque = createApiAction(`${ACTION_KEY}balancePayment`)

export const getTotalBusiness = createApiAction(`${ACTION_KEY}getToatlBusiness`)
export const getTotalPendingOrders = createApiAction(`${ACTION_KEY}getTotalPendingOrders`)
export const getTotalAcceptedOrders = createApiAction(`${ACTION_KEY}getTotalAcceptedOrders`)
export const getTotalOrders = createApiAction(`${ACTION_KEY}getTotalOrders`)
export const getAdvancePendingOrders = createApiAction(`${ACTION_KEY}getAdvancePendingOrders`)

export const getNonVerifiedShipperCustomers = createApiAction(`${ACTION_KEY}getNonVerifiedShipperCustomers`)
export const getVerifiedShipperCustomers = createApiAction(`${ACTION_KEY}getVerifiedShipperCustomers`)

export const createOrder = createApiAction(`${ACTION_KEY}createOrder`)
export const getCustomerDetail = createApiAction(`${ACTION_KEY}getCustomerDetail`)
export const updateCustomer = createApiAction(`${ACTION_KEY}updateCustomer`)
export const createCustomer = createApiAction(`${ACTION_KEY}createCustomer`)

export const updateGstImage = createApiAction(`${ACTION_KEY}updateGstImage`)
export const updatePanImage = createApiAction(`${ACTION_KEY}updatePanImage`)
export const updateAadhar = createApiAction(`${ACTION_KEY}updateAadhar`)
export const updateAddressImage = createApiAction(`${ACTION_KEY}updateAddressImage`)
export const updateVisitingCardImage = createApiAction(`${ACTION_KEY}updateVisitingCardImage`)
export const updateMSME = createApiAction(`${ACTION_KEY}updateMSME`)
export const updateCOI = createApiAction(`${ACTION_KEY}updateCOI`)
export const updateCompanyImage = createApiAction(`${ACTION_KEY}updateCompanyImage`)
export const updateMandiImage = createApiAction(`${ACTION_KEY}updateMandiImage`)
export const updateOffice1 = createApiAction(`${ACTION_KEY}updateOffice1`)
export const updateOffice2 = createApiAction(`${ACTION_KEY}updateOffice2`)
export const updateOffice3 = createApiAction(`${ACTION_KEY}updateOffice3`)
export const updateOwnerPic = createApiAction(`${ACTION_KEY}updateOwnerPic`)
export const getNotificationStatics = createApiAction(`${ACTION_KEY}getNotificationStatics`)
export const getDispatchCalculation = createApiAction(`${ACTION_KEY}getDispatchCalculation`)
export const getTransectionSheet = createApiAction(`${ACTION_KEY}getTransectionSheet`)







