import { call, put, takeLatest } from 'redux-saga/effects' 
import { callApi, responseReceived } from '../actions/api.action.js'
import { checkForProdEnv } from '../../consts/checkForProduction'
import { notification } from '../api/index'
 
import {
   getNotifications,
   updateNotifications
} from '../actions/notification.action'

function* _notifications({ payload }) {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/notification' }))
    const notifications = yield call(notification.getNotifications)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/notification' }))
    if(notifications) {
      yield put(getNotifications.success(notifications.data))
    }
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/notification' }))
    yield put(getNotifications.failure(e))
  }
}

function* updateNotificationAsRead({ payload }) {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/notification' }))
      const notifications = yield call(notification.updateNotification, payload.payload)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/notification' }))
      if(notifications) {
        yield put(updateNotifications.success(notifications.data))
      }
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/notification' }))
      yield put(updateNotifications.failure(e))
    }
  }

export default function* notificationSaga() {
    yield takeLatest(getNotifications.request, _notifications)
    yield takeLatest(updateNotifications.request, updateNotificationAsRead)
}