import axios from "axios";
import moment from "moment";
import { mainInstance } from "../redux/api";
export const dateFormat = "YYYY-MM-DD";
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

export const SessionStorageUser = () => {
  var USER_DETAILS = localStorage.getItem("user:agrigator")
    ? JSON.parse(localStorage.getItem("user:agrigator"))
    : "";
  return USER_DETAILS;
};

export const SessionStorageToken = () => {
  var TOKEN = localStorage.getItem("token:agrigator");
  return TOKEN;
};

export const SessionStorageSubscription = () => {
  var subscription = localStorage.getItem("subscription:agrigator");
  return subscription;
};

export const SessionStorageUserCode = () => {
  var userCodeData = localStorage.getItem("userCodeData:agrigator")
    ? JSON.parse(localStorage.getItem("userCodeData:agrigator"))
    : "";
  return userCodeData;
};

export const SessionStorageRole = () => {
  var userRole = localStorage.getItem("userRole:agrigator");
  return userRole;
};

export const SessionStorageNotification = () => {
  var notifications = localStorage.getItem("notifications:agrigator")
    ? JSON.parse(localStorage.getItem("notifications:agrigator"))
    : "";
  return notifications;
};

export const SessionStorageIsFinace = () => {
  var isFinance = sessionStorage.getItem("isFinance:agrigator");
  return isFinance;
};
export const SessionStorageIsHr = () => {
  var isHr = sessionStorage.getItem("isHr:agrigator");
  return isHr;
};
export const SessionStorageIsTelecaller = () => {
  var isTelecaller = sessionStorage.getItem("isTelecaller:agrigator");
  return isTelecaller;
};

export const dateOption2 = {
  month: "short",
  day: "2-digit",
  year: "numeric",
};

export const hourFormat = {
  hour: "2-digit",
  minute: "2-digit",
};

export const dateOption = {
  month: "short",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const defaultRangeDate = [
  moment(new Date(), dateFormat),
  moment(new Date(), dateFormat),
];

export const defaultRangeDateForDashboard = [
  moment(new Date(), dateFormat),
  moment(new Date(), dateFormat),
];

export const options = [
  {
    value: "AN",
    label: "Andaman and Nicobar Islands",
  },
  {
    value: "AP",
    label: "Andhra Pradesh",
  },
  {
    value: "AR",
    label: "Arunachal Pradesh",
  },
  {
    value: "AS",
    label: "Assam",
  },
  {
    value: "BR",
    label: "Bihar",
  },
  {
    value: "CG",
    label: "Chandigarh",
  },
  {
    value: "CH",
    label: "Chhattisgarh",
  },
  {
    value: "DH",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "DD",
    label: "Daman and Diu",
  },
  {
    value: "DL",
    label: "Delhi",
  },
  {
    value: "GA",
    label: "Goa",
  },
  {
    value: "GJ",
    label: "Gujarat",
  },
  {
    value: "HR",
    label: "Haryana",
  },
  {
    value: "HP",
    label: "Himachal Pradesh",
  },
  {
    value: "JK",
    label: "Jammu and Kashmir",
  },
  {
    value: "JH",
    label: "Jharkhand",
  },
  {
    value: "KA",
    label: "Karnataka",
  },
  {
    value: "KL",
    label: "Kerala",
  },
  {
    value: "LD",
    label: "Lakshadweep",
  },
  {
    value: "MP",
    label: "Madhya Pradesh",
  },
  {
    value: "MH",
    label: "Maharashtra",
  },
  {
    value: "MN",
    label: "Manipur",
  },
  {
    value: "ML",
    label: "Meghalaya",
  },
  {
    value: "MZ",
    label: "Mizoram",
  },
  {
    value: "NL",
    label: "Nagaland",
  },
  {
    value: "OR",
    label: "Odisha",
  },
  {
    value: "PY",
    label: "Puducherry",
  },
  {
    value: "PB",
    label: "Punjab",
  },
  {
    value: "RJ",
    label: "Rajasthan",
  },
  {
    value: "SK",
    label: "Sikkim",
  },
  {
    value: "TN",
    label: "Tamil Nadu",
  },
  {
    value: "TS",
    label: "Telangana",
  },
  {
    value: "TR",
    label: "Tripura",
  },
  {
    value: "UP",
    label: "Uttar Pradesh",
  },
  {
    value: "UK",
    label: "Uttarakhand",
  },
  {
    value: "WB",
    label: "West Bengal",
  },
];

export const getQuery = (filter) => {
  const obj = {
    ...filter,
  };
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};
export const formattedDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getFileExt = (url) => {
  let type = url && url?.substr(url?.lastIndexOf(".") + 1);
  return type;
};

export const openDoc = (doc) => {
  let ext = getFileExt(doc);
  mainInstance
    .get(`/misc/getDocumentBase64?document_name=${doc}`)
    .then((res) => {
      if (res?.data?.base64image) {
        ext == "pdf"
          ? openPDF(res?.data?.base64image)
          : open64Img(res?.data?.base64image);
      }
    });
};

export const open64Img = (data) => {
  var byteCharacters = atob(data);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "image/png;base64" });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const openPDF = (data) => {
  var byteCharacters = atob(data);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export function onylMobileInput() {
  let mobile = document.getElementById("onlyMobile");
  let mobile2 = document.getElementById("onlyMobile2");
  if (mobile) {
    mobile.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
  if (mobile2) {
    mobile2.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
}

export const getAddress = (lat, lng) => {
  try {
    return axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=" +
        "AIzaSyD7IPP_3U7QzRDkTylMF4atkWQPgLet74o",
        {}
      )
      .then((response) => {
        if (response && response.status == 200) {
          return response.data?.results[0]?.formatted_address;
        }
      })
      .catch((error) => {
        return;
      });
  } catch (error) {
    return;
  }
};
