import { createAction } from 'redux-act'


/**
 * Create key action
 * 
 * @param {string} key
 * @returns {function(string): {type: string, payload}}
 */
const createKeyAction = key => (type, ...params) =>
  createAction(`${key}/${type}`, ...params)

export default createKeyAction
