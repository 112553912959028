import { createReducer } from "redux-act";

import { signin, signup, verifyOtp, resendOtp } from "../actions/auth.action";

const initialState = {
  loading: false,
  error: false,
  success: "",
  successOtp: "",
  successResendOtp: "",
  user: {},
  message: "",
  mobile: "",
};

export default createReducer(
  {
    [signin.request]: (state) => ({
      ...state,
      loading: true,
      otpSent: false,
    }),
    [signin.success]: (state, payload) => {
      return {
        ...state,
        loading: false,
        success: payload.data.message,
        otpSent: true,
      };
    },
    [signin.failure]: (state, payload) => {
      return {
        ...state,
        loading: false,
        error: payload,
        otpSent: false,
      };
    },
    [verifyOtp.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [verifyOtp.success]: (state, payload) => {
      return {
        ...state,
        loading: false,
        mobile: payload.mobile,
        successOtp: payload.message,
        user: payload.user,
      };
    },
    [verifyOtp.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [resendOtp.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [resendOtp.success]: (state, payload) => ({
      ...state,
      loading: false,
      successResendOtp: payload.message,
    }),
    [resendOtp.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [signup.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [signup.success]: (state, payload) => {
      return {
        ...state,
        loading: false,
        user: payload.user,
        message: payload.message,
      };
    },
    [signup.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
