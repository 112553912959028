import Auth from "./auth.reducer";
import ThemeSwitcher from "./themeSwitcher.reducer";
import App from "./app.reducer";
import Admin from "./admin.reducer";
import Global from "./global.reducer";
import Notifications from "./notifications.reducer";
import Invoice from "./invoice.reducer";
import Shipper from './shipper.reducer';
export default {
  Auth,
  ThemeSwitcher,
  App,
  Global,
  Invoice,
  Admin,
  Shipper,
  Notifications,
};
