import { call, put, takeLatest } from 'redux-saga/effects' 
import { callApi, responseReceived } from '../actions/api.action.js'
import { checkForProdEnv } from '../../consts/checkForProduction'
import { shipper } from '../api/index'
 
import {
    dispatch,
    activeDispatch,
    viewDispatch,
    paymentDispatch,
    historyDispatch,
    getRecentDispatch,
    makeAdvancePayment,
    makeBalancePayment,
    getTotalActiveOrders,
    getTotalBusiness,
    getTotalOrders,
    getTotalPayment
} from '../actions/shipper.action'

function* totalPayment() {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
    const activeLoadPosts = yield call(shipper.getTotalPayment)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalPayment.success(activeLoadPosts.data))
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalPayment.failure(e))
  }
}

function* totalOrders() {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
    const activeLoadPosts = yield call(shipper.getTotalOrders)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalOrders.success(activeLoadPosts.data))
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalOrders.failure(e))
  }
}

function* totalBusiness() {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
    const activeLoadPosts = yield call(shipper.getTotalBusiness)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalBusiness.success(activeLoadPosts.data))
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalBusiness.failure(e))
  }
}

function* totalActiveOrders() {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
    const activeLoadPosts = yield call(shipper.getTotalActiveOrders)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalActiveOrders.success(activeLoadPosts.data))
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
    yield put(getTotalActiveOrders.failure(e))
  }
}

function* createLoadPost({ payload }) {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/dispatch' }))
    const loadPost = yield call(shipper.dispatch, payload.payload)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/dispatch' }))
    // emit the notification to server ---
    let notification = {
      data: loadPost.data,
      role: localStorage.getItem('userRole:agrigator'),
      action: 'create_dispatch',
      // sender: 
    }
    window.clientSocket.emit('new notification', notification)

    yield put(dispatch.success(loadPost.data))
    payload.history.push('/shipper/active')
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/dispatch' }))
    yield put(dispatch.failure(e))
  }
}

function* createAdvancePayment({payload}) {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    const loadPost = yield call(shipper.makeAdvancePayment, payload.payload)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    yield put(makeAdvancePayment.success(loadPost.data))
    yield put(paymentDispatch.request())
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    yield put(makeAdvancePayment.failure(e))
  }
}
function* createBalancePayment({payload}) {
  try {
    if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    const loadPost = yield call(shipper.makeBalancePayment, payload.payload)
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    yield put(makeBalancePayment.success(loadPost.data))
    yield put(paymentDispatch.request())
  } catch (e) {
    if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/makeAdvancepayment' }))
    yield put(makeBalancePayment.failure(e))
  }
}

function* getActiveDispatch() {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
      const activeLoadPosts = yield call(shipper.activeDispatch)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(activeDispatch.success(activeLoadPosts.data))
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(activeDispatch.failure(e))
    }
  }

  function* getViewDispatch(id) {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/viewDispatch' }))
      const loadPost = yield call(shipper.viewDispatch, id.payload)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/viewDispatch' }))
      yield put(viewDispatch.success(loadPost.data))
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/viewDispatch' }))
      yield put(viewDispatch.failure(e))
    }
  }

  function* getPaymentDispatch() {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
      const paymentLoadPosts = yield call(shipper.paymentDispatch)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(paymentDispatch.success(paymentLoadPosts.data))
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(paymentDispatch.failure(e))
    }
  }

  function* getHistoryDispatch() {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
      const historyLoadPosts = yield call(shipper.historyDispatch)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(historyDispatch.success(historyLoadPosts.data))
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(historyDispatch.failure(e))
    }
  }

  function* getRecentLoadPosts() {
    try {
      if (checkForProdEnv) yield put(callApi.request({ startTime: new Date().getTime(), endpoint: '/shipper/active' }))
      const recentLoadPosts = yield call(shipper.getRecentDispatch)
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(getRecentDispatch.success(recentLoadPosts.data))
    } catch (e) {
      if (checkForProdEnv) yield put(responseReceived.request({ endTime: new Date().getTime(), endpoint: '/shipper/active' }))
      yield put(getRecentDispatch.failure(e))
    }
  }

export default function* shipperSage() {
    yield takeLatest(dispatch.request, createLoadPost)
    yield takeLatest(activeDispatch.request, getActiveDispatch)
    yield takeLatest(viewDispatch.request, getViewDispatch)
    yield takeLatest(paymentDispatch.request, getPaymentDispatch)
    yield takeLatest(historyDispatch.request, getHistoryDispatch)
    yield takeLatest(getRecentDispatch.request, getRecentLoadPosts)

    yield takeLatest(makeAdvancePayment.request, createAdvancePayment)
    yield takeLatest(makeBalancePayment.request, createBalancePayment)

    yield takeLatest(getTotalActiveOrders.request, totalActiveOrders)
    yield takeLatest(getTotalBusiness.request, totalBusiness)
    yield takeLatest(getTotalOrders.request, totalOrders)
    yield takeLatest(getTotalPayment.request, totalPayment)

}