import { call, put, takeLatest } from "redux-saga/effects";
import { callApi, responseReceived } from "../actions/api.action.js";
import { checkForProdEnv } from "../../consts/checkForProduction";
import { auth, mainInstance } from "../api/index";

import {
  signin,
  signup,
  resendOtp as _resendOtp,
  verifyOtp as _verifyOtp,
  logout as _logout,
} from "../actions/auth.action";
import { PUBLIC_ROUTE } from "../../routes/route.constants.js";
import { notification } from "antd";

function* logout({ payload }) {
  try {
    yield call(auth.logout, { mobile: payload?.mobile });
    localStorage.removeItem("token:agrigator");
    localStorage.removeItem("user:agrigator");
    localStorage.removeItem("userCodeData:agrigator");
    localStorage.clear();
    sessionStorage.clear();
    payload.history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 1);
  } catch (e) {
    yield put(_logout.failure(e));
  }
}

function* resendOtp({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/users/resendOtp",
        })
      );
    const user = yield call(auth.resendOtp, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/resendOtp",
        })
      );
    yield put(_resendOtp.success(user.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/resendOtp",
        })
      );
    yield put(_resendOtp.failure(e));
  }
}

function* login({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/users/login",
        })
      );
    const user = yield call(auth.login, payload.payload);
    if (user?.data && user?.data?.type === "failure") {
      notification.error({ message: user?.data?.message });
      yield put(signin.failure(user?.data));
      return;
    }
    yield put(
      responseReceived.request({
        endTime: new Date().getTime(),
        endpoint: "/users/login",
      })
    );
    yield put(signin.success(user));
  } catch (e) {
    console.log("user0000", e);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/login",
        })
      );
    yield put(signin.failure(e));
  }
}

function* verifyOtp({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/users/login",
        })
      );
    const user = yield call(auth.verifyOtp, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/login",
        })
      );
    localStorage.setItem("token:agrigator", user.data.token);
    if (user.data.userProfile === "admin_profile") {
      localStorage.setItem("userRole:agrigator", "admin");
      localStorage.setItem("user:agrigator", JSON.stringify(user.data.user[0]));
      if (user?.data?.token) {
        mainInstance.defaults.headers.common[
          "Authorization"
        ] = `JWT ${user?.data?.token}`;
        mainInstance.defaults.headers.common[
          "Authorization"
        ] = `JWT ${user?.data?.token}`;
      }
      if (
        user.data.user[0]?.userType === "Finance" ||
        (user.data.user[0]?.userType === "FinanceAdmin" &&
          user.data.user[0]?.role === "108")
      ) {
        payload.history.push("/admin/financeShipper");
      } else if (
        user.data.user[0]?.userType === "HRAgent" ||
        (user.data.user[0]?.userType === "HRAdmin" &&
          user.data.user[0]?.role === "109")
      ) {
        payload.history.push("/admin/dashboard-hr");
      } else if (
        user.data.user[0]?.userType === "Supply" ||
        user.data.user[0]?.userType === "Customer"
      ) {
        payload.history.push({
          pathname: PUBLIC_ROUTE.PAGE_403,
          state: {
            data: user.data,
          },
        });
      }
      else if (
        user.data.user[0]?.userType === "TelecallerHead"
      ) {
        payload.history.push("admin/manageteams");
      }
      else if (
        user.data.user[0]?.userType === "Telecaller"
      ) {
        payload.history.push("admin/telecaller/profile");
      }
      else {
        payload.history.push("/admin/dispatch");
      }
    } else if (!user.data.isActive) {
      payload.history.push({
        pathname: PUBLIC_ROUTE.PAGE_403,
        state: {
          data: user.data,
        },
      });
    } else if (user.data.deleted_by_admin) {
      payload.history.push({
        pathname: PUBLIC_ROUTE.PAGE_403,
        state: {
          data: user.data,
        },
      });
    }
    yield put(_verifyOtp.success(user.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/login",
        })
      );
    yield put(_verifyOtp.failure(e));
  }
}

function* registration({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/users/updateUser",
        })
      );
    const user = yield call(auth.updateUser, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/updateUser",
        })
      );
    if (user.data.type === "success") {
      yield put(signup.success(user));
      localStorage.setItem("token:agrigator", user.data.token);
      localStorage.setItem("user:agrigator", JSON.stringify(user.data.user));
      localStorage.setItem("userRole:agrigator", "shipper");
      payload.history.push("/shipper/dispatch");
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/users/updateUser",
        })
      );
    yield put(signup.failure(e));
  }
}

export default function* authSaga() {
  yield takeLatest(signin.request, login);
  yield takeLatest(_verifyOtp.request, verifyOtp);
  yield takeLatest(_resendOtp.request, resendOtp);
  yield takeLatest(signup.request, registration);
  yield takeLatest(_logout.request, logout);
}