import { createReducer } from "redux-act";

import {
  dispatch,
  viewDispatch,
  updateStatus,
  updateInvoice,
  updateIntransitDetails,
  getTotalPendingOrders,
  getTotalOrders,
  getTotalBusiness,
  getTotalAcceptedOrders,
  getAdvancePendingOrders,
  getNonVerifiedShipperCustomers,
  getVerifiedShipperCustomers,
  getCustomerDetail,
  dispatchWithPagination,
  getNotificationStatics,
  getDispatchCalculation,
  getTransectionSheet,
} from "../actions/admin.action";

const initialState = {
  loading: false,
  error: false,
  loadPosts: [],
  updatedLoadPost: [],
  loadPost: [],
  loadPostShipper: [],
  updatedIntransitPost: [],
  totalPendingOrders: "",
  totalOrders: "",
  totalBusiness: "",
  totalAcceptedOrders: "",
  totalAdvancePendingOrders: "",
  nonVerifiedShipperCustomers: [],
  verifiedShipperCustomers: [],
  verifiedShipperCustomersTotalCount: 0,
  customer: "",
  isSuperAdmin: false,
  dispatchLoadTotalCount: 0,
  notificationStatics: "",
  calculation: {},
  transectionSheet: [],
};

export default createReducer(
  {
    [getCustomerDetail.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getCustomerDetail.success]: (state, payload) => ({
      ...state,
      loading: false,
      customer: payload.customer,
    }),
    [getCustomerDetail.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getNonVerifiedShipperCustomers.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getNonVerifiedShipperCustomers.success]: (state, payload) => ({
      ...state,
      loading: false,
      nonVerifiedShipperCustomers: payload.customer,
    }),
    [getNonVerifiedShipperCustomers.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getVerifiedShipperCustomers.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getVerifiedShipperCustomers.success]: (state, payload) => ({
      ...state,
      loading: false,
      verifiedShipperCustomers: payload.customer,
      verifiedShipperCustomersTotalCount: payload.total,
    }),
    [getVerifiedShipperCustomers.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getTotalOrders.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getTotalOrders.success]: (state, payload) => ({
      ...state,
      loading: false,
      totalOrders: payload.loadPost,
    }),
    [getTotalOrders.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getTotalPendingOrders.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getTotalPendingOrders.success]: (state, payload) => ({
      ...state,
      loading: false,
      totalPendingOrders: payload.loadPost,
    }),
    [getTotalPendingOrders.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getTotalBusiness.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getTotalBusiness.success]: (state, payload) => ({
      ...state,
      loading: false,
      totalBusiness: payload.loadPost,
    }),
    [getTotalBusiness.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getTotalAcceptedOrders.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getTotalAcceptedOrders.success]: (state, payload) => ({
      ...state,
      loading: false,
      totalAcceptedOrders: payload.loadPost,
    }),
    [getTotalAcceptedOrders.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [getAdvancePendingOrders.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getAdvancePendingOrders.success]: (state, payload) => ({
      ...state,
      loading: false,
      totalAdvancePendingOrders: payload.loadPost,
    }),
    [getAdvancePendingOrders.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [updateIntransitDetails.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [updateIntransitDetails.success]: (state, payload) => ({
      ...state,
      loading: false,
      updatedIntransitPost: payload.loadPost,
    }),
    [updateIntransitDetails.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [updateInvoice.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [updateInvoice.success]: (state, payload) => ({
      ...state,
      loading: false,
      loadPost: payload.loadPost,
    }),
    [updateInvoice.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    [dispatch.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [dispatch.success]: (state, payload) => ({
      ...state,
      loading: false,
      loadPosts: payload.loadPost,
    }),
    [dispatch.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [dispatchWithPagination.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [dispatchWithPagination.success]: (state, payload) => ({
      ...state,
      loading: false,
      loadPosts: payload.loadPost,
      dispatchLoadTotalCount: payload.totalCount,
    }),
    [dispatchWithPagination.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [viewDispatch.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [viewDispatch.success]: (state, payload) => ({
      ...state,
      loading: false,
      loadPostShipper: [...payload.loadPost],
    }),
    [viewDispatch.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [updateStatus.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [updateStatus.success]: (state, payload) => ({
      ...state,
      loading: false,
      updatedLoadPost: payload.loadPost,
    }),
    [updateStatus.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [getNotificationStatics.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getNotificationStatics.success]: (state, payload) => ({
      ...state,
      loading: false,
      notificationStatics: payload.notificationStatics,
    }),
    [getNotificationStatics.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
      notificationStatics: "",
    }),
    [getDispatchCalculation.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getDispatchCalculation.success]: (state, payload) => ({
      ...state,
      loading: false,
      calculation: payload,
    }),
    [getDispatchCalculation.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
      calculation: "",
    }),
    [getTransectionSheet.request]: (state) => ({
      ...state,
      loading: true,
    }),
    [getTransectionSheet.success]: (state, payload) => ({
      ...state,
      loading: false,
      transectionSheet: payload,
    }),
    [getTransectionSheet.failure]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload,
      transectionSheet: "",
    }),
  },
  initialState
);
