import { all, call, put, takeLatest } from "redux-saga/effects";
import { callApi, responseReceived } from "../actions/api.action.js";
import { checkForProdEnv } from "../../consts/checkForProduction";
import { admin } from "../api/index";

import {
  dispatch,
  updateStatus,
  updateVehicleDetails,
  updateInvoice,
  createAdvancePayment,
  createBalancePayment,
  updateIntransitDetails,
  advancePaymentByCheque,
  balancePaymentByCheque,
  getAdvancePendingOrders,
  getTotalAcceptedOrders,
  getTotalBusiness,
  getTotalOrders,
  getTotalPendingOrders,
  getNonVerifiedShipperCustomers,
  getVerifiedShipperCustomers,
  createOrder,
  getCustomerDetail,
  updateCustomer,
  createCustomer,
  updateGstImage,
  updateAadhar,
  updateAddressImage,
  updatePanImage,
  updateVisitingCardImage,
  updateCOI,
  updateMSME,
  updateCompanyImage,
  updateMandiImage,
  updateOffice1,
  updateOffice2,
  updateOffice3,
  updateOwnerPic,
  viewDispatch,
  dispatchWithPagination,
  getNotificationStatics,
  getDispatchCalculation,
  getTransectionSheet,
} from "../actions/admin.action";
import { SessionStorageRole } from "../../consts/Fuction.js";
import isTeleCaller from "../../containers/Admin/Telecaller.js";

function* addCustomer(payload) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    const customer = yield call(admin.createCustomer, payload.payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield put(createCustomer.success(customer.data));
    payload.payload.history.push("/admin/customer/nonverified");
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield put(createCustomer.failure(e));
  }
}

function* uploadCOI({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateCoiImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateCOI.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateCOI.failure(e));
  }
}

function* uploadMSME({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateMsmeImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateMSME.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateMSME.failure(e));
  }
}

function* uploadCompanyImage({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateCompanyImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateCompanyImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateCompanyImage.failure(e));
  }
}

function* uploadMandiImage({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateMandiImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateMandiImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateMandiImage.failure(e));
  }
}

function* uploadOffice1({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateOffice1Image, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateOffice1.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateOffice1.failure(e));
  }
}

function* uploadOffice2({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateOffice2Image, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateOffice2.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateOffice2.failure(e));
  }
}

function* uploadOffice3({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateOffice3Image, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateOffice3.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateOffice3.failure(e));
  }
}

function* uploadOwnerImage({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateOwnerImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateOwnerPic.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateOwnerPic.failure(e));
  }
}

function* uploadVisitingCard({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(
      admin.updateVisitingCardImage,
      payload.formData
    );
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateVisitingCardImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateVisitingCardImage.failure(e));
  }
}

function* uploadAddressImage({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateAddressImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateAddressImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateAddressImage.failure(e));
  }
}

function* uploadAadhar({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateAadharImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateAadhar.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateAadhar.failure(e));
  }
}

function* uploadPan({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updatePanImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updatePanImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updatePanImage.failure(e));
  }
}

function* uploadGst({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const customer = yield call(admin.updateGstImage, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (customer) {
      yield put(updateGstImage.success(customer.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateGstImage.failure(e));
  }
}

function* customerDeatils(id) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getCustomerDetail",
        })
      );
    const customer = yield call(admin.getCustomerDetail, id.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getCustomerDetail",
        })
      );
    yield put(getCustomerDetail.success(customer.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getCustomerDetail",
        })
      );
    yield put(getCustomerDetail.failure(e));
  }
}

function* createLoadPost(payload) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    const loadPost = yield call(admin.createOrder, payload.payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield getNotificationsStaticsF();
    yield put(createOrder.success(loadPost.data));
    // check user is not telecaller
    var USER_DETAILS = localStorage.getItem("user:agrigator") ? JSON.parse(localStorage.getItem("user:agrigator")) : "";
    if (USER_DETAILS.role == '110' && USER_DETAILS.userType == 'Telecaller') {
      payload.payload.history.push("/admin/placementtask");
    }else{
      payload.payload.history.push("/admin/dispatch");
    }
    // if (!isTeleCaller()) {
    //   payload.payload.history.push("/admin/dispatch");
    // }

  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield put(createOrder.failure(e));
  }
}

function* updateCustomerDetails(payload) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    const customer = yield call(admin.updateCustomer, payload.payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield put(updateCustomer.success(customer.data));
    payload.payload.history.push(
      `/admin/customer/view/${customer.data.customer._id}`
    );
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/createOrder",
        })
      );
    yield put(updateCustomer.failure(e));
  }
}

function* nonVerifiedShipperCustomers() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getNonVerifiedShipperCustomers",
        })
      );
    const customers = yield call(admin.nonVerifiedShipperCustomers);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getNonVerifiedShipperCustomers",
        })
      );
    yield put(getNonVerifiedShipperCustomers.success(customers.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getNonVerifiedShipperCustomers",
        })
      );
    yield put(getNonVerifiedShipperCustomers.failure(e));
  }
}

function* verifiedShipperCustomers(payload) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getVerifiedShipperCustomers",
        })
      );
    const customers = yield call(
      admin.verifiedShipperCustomers,
      payload.payload
    );
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getVerifiedShipperCustomers",
        })
      );
    let _data = customers.data;

    if (_data && _data.customer.length > 0) {
      yield put(getVerifiedShipperCustomers.success(_data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getVerifiedShipperCustomers",
        })
      );
    yield put(getVerifiedShipperCustomers.failure(e));
  }
}

function* advanceCheque({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/advanceCheque",
        })
      );
    const loadPost = yield call(admin.advancePaymentByCheque, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/advanceCheque",
        })
      );
    if (loadPost) {
      yield put(advancePaymentByCheque.success(loadPost.data));
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "advance_payment_cheque",
      };
      /* window.clientSocket.emit('new notification', notification) */
      payload.history.push(`/admin/dispatch/${loadPost.data.loadPost.orderId}`);
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/advanceCheque",
        })
      );
    yield put(advancePaymentByCheque.failure(e));
  }
}

function* balanceCheque({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/balanceCheque",
        })
      );
    const loadPost = yield call(admin.balancePaymentByCheque, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/balanceCheque",
        })
      );
    if (loadPost) {
      yield put(balancePaymentByCheque.success(loadPost.data));
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "balance_payment_cheque",
      };
      /* window.clientSocket.emit('new notification', notification) */
      payload.history.push(`/admin/dispatch/${loadPost.data.loadPost.orderId}`);
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/balanceCheque",
        })
      );
    yield put(balancePaymentByCheque.failure(e));
  }
}

function* advancePayment({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/advancePayment",
        })
      );
    const loadPost = yield call(admin.createAdvancePayment, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/advancePayment",
        })
      );
    if (loadPost) {
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "order_status_update",
      };
      /* window.clientSocket.emit('new notification', notification) */
      yield put(dispatchWithPagination.request(payload?.filter));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/advancePayment",
        })
      );
    yield put(createAdvancePayment.failure(e));
  }
}

function* balancePayment({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/balancePayment",
        })
      );
    const loadPost = yield call(admin.createBalancePayment, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/balancePayment",
        })
      );
    if (loadPost) {
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "order_status_update",
      };
      /* window.clientSocket.emit('new notification', notification) */
      yield put(dispatchWithPagination.request(payload?.filter));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/balancePayment",
        })
      );
    yield put(createBalancePayment.failure(e));
  }
}

function* updateTransit({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const loadPost = yield call(admin.updateIntransitDetail, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (loadPost) {
      yield put(updateIntransitDetails.success(loadPost.data));
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "order_status_update",
      };
      /* window.clientSocket.emit('new notification', notification) */
      payload.history.push(`/admin/dispatch/${loadPost.data.loadPost.orderId}`);
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateIntransitDetails.failure(e));
  }
}

function* updateDispatchInvoice({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateInvoice",
        })
      );
    const loadPost = yield call(admin.updateInvoice, payload.formData);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateInvoice",
        })
      );
    if (loadPost) {
      yield put(updateInvoice.success(loadPost.data));
      payload.history.push(`/admin/dispatch/${loadPost.data.loadPost.orderId}`);
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateInvoice",
        })
      );
    yield put(updateInvoice.failure(e));
  }
}

function* updateVehicle({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    const loadPost = yield call(admin.updateVehicleDetail, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    if (loadPost) {
      payload.history.push(`/admin/dispatch/${payload.payload.orderId}`);
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateVehicleDetails",
        })
      );
    yield put(updateVehicleDetails.failure(e));
  }
}

function* getDispatch() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getDispatch",
        })
      );
    const dispatches = yield call(admin.dispatch);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispatch",
        })
      );
    yield put(dispatch.success(dispatches.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispatch",
        })
      );
    yield put(dispatch.failure(e));
  }
}
function* getDispatchWithPagination(payload) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getDispatchWithPagination",
        })
      );
    const dispatches = yield call(
      admin.dispatchWithPagination,
      payload.payload
    );
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispatchWithPagination",
        })
      );
    yield put(dispatchWithPagination.success(dispatches.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispatchWithPagination",
        })
      );
    yield put(dispatchWithPagination.failure(e));
  }
}

function* updateDispatchStatus({ payload }) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.updateStatus, payload.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      yield put(dispatchWithPagination.request(payload?.filter));
      let notification = {
        data: loadPost.data,
        role: SessionStorageRole(),
        action: "order_status_update",
      };
      /* window.clientSocket.emit('new notification', notification) */
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(updateStatus.failure(e));
  }
}

function* getTotalOrdersCount() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.totalOrders);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      if (checkForProdEnv)
        yield put(
          responseReceived.request({
            endTime: new Date().getTime(),
            endpoint: "/admin/getDispatch",
          })
        );
      yield put(getTotalOrders.success(loadPost.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(getTotalOrders.failure(e));
  }
}

function* getTotalPendingOrdersCount() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.totalPendingOrders);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      if (checkForProdEnv)
        yield put(
          responseReceived.request({
            endTime: new Date().getTime(),
            endpoint: "/admin/getDispatch",
          })
        );
      yield put(getTotalPendingOrders.success(loadPost.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(getTotalPendingOrders.failure(e));
  }
}

function* getAdvancePendingOrdersCount() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.advancePendingOrders);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      if (checkForProdEnv)
        yield put(
          responseReceived.request({
            endTime: new Date().getTime(),
            endpoint: "/admin/getDispatch",
          })
        );
      yield put(getAdvancePendingOrders.success(loadPost.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(getAdvancePendingOrders.failure(e));
  }
}

function* getTotalAcceptedOrdersCount() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.totalAcceptedOrders);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      if (checkForProdEnv)
        yield put(
          responseReceived.request({
            endTime: new Date().getTime(),
            endpoint: "/admin/getDispatch",
          })
        );
      yield put(getTotalAcceptedOrders.success(loadPost.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(getTotalAcceptedOrders.failure(e));
  }
}

function* getTotalBusinessCount() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    const loadPost = yield call(admin.totalBusiness);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    if (loadPost) {
      if (checkForProdEnv)
        yield put(
          responseReceived.request({
            endTime: new Date().getTime(),
            endpoint: "/admin/getDispatch",
          })
        );
      yield put(getTotalBusiness.success(loadPost.data));
    }
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/updateStatus",
        })
      );
    yield put(getTotalBusiness.failure(e));
  }
}

function* getViewDispatch(id) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/shipper/viewDispatch",
        })
      );
    const loadPost = yield call(admin.viewDispatch, id.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/shipper/viewDispatch",
        })
      );
    yield put(viewDispatch.success(loadPost.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/shipper/viewDispatch",
        })
      );
    yield put(viewDispatch.failure(e));
  }
}
function* getNotificationsStaticsF() {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/notification/statics",
        })
      );
    const notificationStaticsData = yield call(admin.notificationsStatics, {});
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/notification/statics",
        })
      );
    yield put(
      getNotificationStatics.success({
        notificationStatics: notificationStaticsData.data.data?.statics,
      })
    );
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/notification/statics",
        })
      );
    yield put(getNotificationStatics.failure(e));
  }
}
function* getDispatchCalculationF(id) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getDispachCalculation",
        })
      );
    const calculation = yield call(admin.dispatchCaluclation, id.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispachCalculation",
        })
      );
    yield put(getDispatchCalculation.success(calculation.data?.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getDispachCalculation",
        })
      );
    yield put(getDispatchCalculation.failure(e));
  }
}
function* getTransectionSheetF(filter) {
  try {
    if (checkForProdEnv)
      yield put(
        callApi.request({
          startTime: new Date().getTime(),
          endpoint: "/admin/getTransectionSheet",
        })
      );
    const sheet = yield call(admin.transectionSheet, filter.payload);
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getTransectionSheet",
        })
      );
    yield put(getTransectionSheet.success(sheet.data));
  } catch (e) {
    if (checkForProdEnv)
      yield put(
        responseReceived.request({
          endTime: new Date().getTime(),
          endpoint: "/admin/getTransectionSheet",
        })
      );
    yield put(getTransectionSheet.failure(e));
  }
}
export default function* adminSaga() {
  yield takeLatest(dispatch.request, getDispatch);
  yield takeLatest(dispatchWithPagination.request, getDispatchWithPagination);
  yield takeLatest(updateStatus.request, updateDispatchStatus);
  yield takeLatest(updateVehicleDetails.request, updateVehicle);
  yield takeLatest(updateInvoice.request, updateDispatchInvoice);

  yield takeLatest(createAdvancePayment.request, advancePayment);
  yield takeLatest(createBalancePayment.request, balancePayment);

  yield takeLatest(updateIntransitDetails.request, updateTransit);
  yield takeLatest(advancePaymentByCheque.request, advanceCheque);
  yield takeLatest(balancePaymentByCheque.request, balanceCheque);

  yield takeLatest(
    getAdvancePendingOrders.request,
    getAdvancePendingOrdersCount
  );
  yield takeLatest(getTotalAcceptedOrders.request, getTotalAcceptedOrdersCount);
  yield takeLatest(getTotalBusiness.request, getTotalBusinessCount);
  yield takeLatest(getTotalOrders.request, getTotalOrdersCount);
  yield takeLatest(getTotalPendingOrders.request, getTotalPendingOrdersCount);

  yield takeLatest(
    getNonVerifiedShipperCustomers.request,
    nonVerifiedShipperCustomers
  );
  yield takeLatest(
    getVerifiedShipperCustomers.request,
    verifiedShipperCustomers
  );

  yield takeLatest(createOrder.request, createLoadPost);
  yield takeLatest(getCustomerDetail.request, customerDeatils);
  yield takeLatest(updateCustomer.request, updateCustomerDetails);
  yield takeLatest(createCustomer.request, addCustomer);

  yield takeLatest(updateGstImage.request, uploadGst);
  yield takeLatest(updatePanImage.request, uploadPan);
  yield takeLatest(updateAadhar.request, uploadAadhar);
  yield takeLatest(updateAddressImage.request, uploadAddressImage);
  yield takeLatest(updateVisitingCardImage.request, uploadVisitingCard);
  yield takeLatest(updateCOI.request, uploadCOI);
  yield takeLatest(updateMSME.request, uploadMSME);
  yield takeLatest(updateCompanyImage.request, uploadCompanyImage);
  yield takeLatest(updateMandiImage.request, uploadMandiImage);
  yield takeLatest(updateOffice1.request, uploadOffice1);
  yield takeLatest(updateOffice2.request, uploadOffice2);
  yield takeLatest(updateOffice3.request, uploadOffice3);
  yield takeLatest(updateOwnerPic.request, uploadOwnerImage);
  yield takeLatest(viewDispatch.request, getViewDispatch);
  yield takeLatest(getNotificationStatics.request, getNotificationsStaticsF);
  yield takeLatest(getDispatchCalculation.request, getDispatchCalculationF);
  yield takeLatest(getTransectionSheet.request, getTransectionSheetF);
}
