import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "antd/dist/antd.css";

import GlobalStyles from "./assets/styles/globalStyle";
import AppProvider from "./AppProvider";
import { store, history } from "./redux/store";
import Routes from "./routes/router";
import "./App.css";
import { SessionStorageToken } from "./consts/Fuction";
import { LoadingProvider } from "./components/CustomLoader/LoadingContext";
import { FinacePortalProvider } from "./components/PortalContext";
import { mainInstance } from "./redux/api";

/* if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  window.clientSocket = socketIOClient("http://localhost:3000");
} else {
  window.clientSocket = socketIOClient("https://admin-prod.agrigator.co/");
} */

function App() {
  const token = SessionStorageToken();
  if (token) {
    mainInstance.defaults.headers.common["Authorization"] = `JWT ${token}`;
  }
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <FinacePortalProvider>
      <LoadingProvider>
        <Provider store={store}>
          <AppProvider>
            <>
              <GlobalStyles />
              <Routes history={history} />
            </>
          </AppProvider>
        </Provider>
      </LoadingProvider>
    </FinacePortalProvider>
  );
}

export default App;
