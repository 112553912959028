import { SessionStorageUser } from "../../consts/Fuction";

let USER_DETAILS = SessionStorageUser();

const isTeleCaller = () => {
    try {
        if (USER_DETAILS.role === '110' && USER_DETAILS.userType === 'Telecaller') {
            return true;
        }
        else {
            return false;
        }

    } catch (error) {
        return false;
    }
}
export default isTeleCaller;