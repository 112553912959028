import { createApiAction as _createApiAction, createKeyAction } from '../../utils/index'

const ACTION_KEY = 'shipper/'

const createApiAction = _createApiAction(ACTION_KEY)
const createAction = createKeyAction(ACTION_KEY)

export const dispatch = createApiAction(`${ACTION_KEY}dispatch`)
export const activeDispatch = createApiAction(`${ACTION_KEY}active`)
export const viewDispatch = createApiAction(`${ACTION_KEY}viewDispatch`)
export const paymentDispatch = createApiAction(`${ACTION_KEY}payment`)
export const makeAdvancePayment = createApiAction(`${ACTION_KEY}makeAdvancePayment`)
export const makeBalancePayment = createApiAction(`${ACTION_KEY}makeBalancePayment`)

export const historyDispatch = createApiAction(`${ACTION_KEY}history`)
export const getRecentDispatch = createApiAction(`${ACTION_KEY}getRecentDispatch`)

export const getTotalPayment = createApiAction(`${ACTION_KEY}getTotalPayment`)
export const getTotalActiveOrders = createApiAction(`${ACTION_KEY}getTotalActiveOrders`)
export const getTotalOrders = createApiAction(`${ACTION_KEY}getTotalOrders`)
export const getTotalBusiness = createApiAction(`${ACTION_KEY}getTotalBusiness`)

export const clearMessage = createAction(`${ACTION_KEY}clear`)