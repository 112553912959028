import { all } from 'redux-saga/effects'

import auth from './auth.saga'
import admin from './admin.saga'
import notification from './notification.saga'
import shipper from "./shipper.saga";
export default function* () {
    yield all([
        auth(),
        admin(),
        notification(),
        shipper(),
    ])
}
