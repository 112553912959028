import React from "react";
import { Bars, Circles } from "react-loader-spinner";

const CustomLoader = ({ loading }) => {
  return (
    <div className="customLoader">
      <Circles
        heigth="100"
        width="100"
        color="grey"
        ariaLabel="loading-indicator"
      />
    </div>
  );
};
export default CustomLoader;
