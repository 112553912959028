import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        let myDiv = document.getElementById("scrollTopDiv");
        let myDivInner = document.getElementById("scrollableDiv");
        if (myDiv && myDivInner) {
            myDiv.scrollTop = 0;
            myDivInner.scrollTop = 0;
        }
    }, [pathname]);
    return null;
}
export default ScrollToTop
