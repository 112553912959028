// LoadingContext.js
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import CustomLoader from ".";
export const LoadingContext = createContext({
  loading: false,
  setLoading: (loading) => {},
});

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <LoadingContext.Provider value={value}>
      {loading && <CustomLoader loading={loading} />}
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }

  return context;
}
