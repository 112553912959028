import actions from "../actions/global.action";

const initialState = {
  currentPage: 1,
  currentLocation: "/",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_CURRENTPAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case actions.UPDATE_CURRENTLOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    default:
      return state;
  }
}
