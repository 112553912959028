import { createReducer } from 'redux-act'

import {
    dispatch,
    activeDispatch,
    viewDispatch,
    paymentDispatch,
    historyDispatch,
    getRecentDispatch,
    clearMessage,
    getTotalPayment,
    getTotalOrders,
    getTotalBusiness,
    getTotalActiveOrders
} from '../actions/shipper.action'

const initialState = {
    loading: false,
    error: false,
    successDispatch: '',
    successOtp: '',
    successResendOtp: '',
    loadPosts: [],
    loadPost: [],
    message: '',
    paymentLoadPosts: [],
    historyLoadPosts: [],
    recentDispatch: [],
    totalPayment: '',
    totalOrders: '',
    totalBusiness: '',
    totalActiveOrders: ''
}

export default createReducer({

    [getTotalPayment.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getTotalPayment.success]: (state, payload) => ({
        ...state,
        loading: false,
        totalPayment: payload.loadPost
    }),
    [getTotalPayment.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [getTotalOrders.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getTotalOrders.success]: (state, payload) => ({
        ...state,
        loading: false,
        totalOrders: payload.loadPost
    }),
    [getTotalOrders.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [getTotalBusiness.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getTotalBusiness.success]: (state, payload) => ({
        ...state,
        loading: false,
        totalBusiness: payload.loadPost
    }),
    [getTotalBusiness.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [getTotalActiveOrders.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getTotalActiveOrders.success]: (state, payload) => ({
        ...state,
        loading: false,
        totalActiveOrders: payload.loadPost
    }),
    [getTotalActiveOrders.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [getRecentDispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [getRecentDispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        recentDispatch: payload.loadPost
    }),
    [getRecentDispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [historyDispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [historyDispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        historyLoadPosts: payload.loadPost
    }),
    [historyDispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [dispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [dispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        successDispatch: payload.message,
    }),
    [dispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [activeDispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [activeDispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        loadPosts: payload.loadPost
    }),
    [activeDispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [viewDispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [viewDispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        loadPost: payload.loadPost
    }),
    [viewDispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),

    [paymentDispatch.request]: (state) => ({
        ...state,
        loading: true
    }),
    [paymentDispatch.success]: (state, payload) => ({
        ...state,
        loading: false,
        paymentLoadPosts: payload.loadPost
    }),
    [paymentDispatch.failure]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
    }),
    [clearMessage]: (state, payload) => ({
        ...state,
        successDispatch: ''
    })
}, initialState)