import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider, addLocaleData } from 'react-intl'
import localeEn from 'react-intl/locale-data/en'

import messagesEn from './translations/en.json'

import themes from './config/theme/theme.config';

addLocaleData([...localeEn, ])
const messages = {
  'en': messagesEn
}

const language = navigator.language.split(/[-_]/)[0]  // language without region code


export default function AppProvider({ children }) {
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  return (
    <ConfigProvider>
      <IntlProvider locale={language} messages={messages[language]}>
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
        </IntlProvider>
    </ConfigProvider>
  );
}
