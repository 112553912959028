import { createAction } from 'redux-act'

/**
 * Create API action
 * 
 * @param {string} key
 * @param {string} type
 */
const createApiAction = key => type => {
  const name = `${key}/${type}`
  // //console.log("................. createAction",key," -----" ,type)
  return {
    request: createAction(`${name}::REQUEST`),
    success: createAction(`${name}::SUCCESS`),
    failure: createAction(`${name}::FAILURE`),
    cancel: createAction(`${name}::CANCEL`),
    fromState: createAction(`${name}::FROM_STATE`),
    throttle: createAction(`${name}::THROTTLE`)
  }
}

export default createApiAction
