

import { createApiAction as _createApiAction, createKeyAction } from '../../utils'

const ACTION_KEY = 'apiAction/'

const createApiAction = _createApiAction(ACTION_KEY)
const createAction = createKeyAction(ACTION_KEY)

export const callApi = createApiAction(`${ACTION_KEY}CALL_API`)
export const responseReceived = createApiAction(`${ACTION_KEY}RESPONSE_RECEIVED`)
export const resetApiCounter = createAction(`RESET_API_COUNTER`)
