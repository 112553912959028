import React, { useState } from "react";

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);

  const handleOnError = (error) => {
    setHasError(true);
  };

  if (hasError) {
    return <p>Loading failed! Please reload.</p>;
  }

  return (
    <>
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          onError: handleOnError,
        })
      )}
    </>
  );
};

export default ErrorBoundary;
