import actions from "../actions/invoice.action";

const initialState = {
  currentLoad: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_LOAD:
      return {
        ...state,
        currentLoad: action.payload,
      };

    default:
      return state;
  }
}
