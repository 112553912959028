// LoadingContext.js
import { createContext, useContext, useState } from "react";
import React from "react";
export const PortalContext = createContext({
  financePortal: false,
  setFinancePortal: () => {},
});

export function FinacePortalProvider({ children }) {
  const [financePortal, setFinancePortal] = useState(false);
  const value = { financePortal, setFinancePortal };
  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
}

export function usePortal() {
  const context = useContext(PortalContext);
  return context;
}
